export class Token {

    constructor(key) {
        return this.getWithExpiry(key)
    }


    setWithExpiry(key, value, ttl) {
        const now = new Date()
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }


    getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value;
    }

    hasToken() {
        return (this.getWithExpiry('user') !== null) ? true : false;
    }


    updateATE(key) {
        if (this.hasToken()) {
            // update token expiry time 24 hours
            this.setWithExpiry(key, this.getWithExpiry(key), 86400000)
            return true;
        } else return false;
    }

    getUpdated(key) {
        if (this.updateATE(key)) {
            return this.getWithExpiry(key);
        } else return null;
    }
}