const XAPIKEY = "anE4ser6h1vIc2pM22weB02t02A2ipA8";
// const basePath = "https://ath-apiqa.dvconsulting.org/";
const basePath ="http://athena-api-dev.dvconsulting.org:4000/";
const versionPath = "api/v1/";
const baseURL = basePath + versionPath;

export {
    XAPIKEY,
    baseURL,
    basePath
}