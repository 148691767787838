import {
    // eslint-disable-next-line no-unused-vars
    API, // for "Content-Type": "application/json",
    errCheck // for forbidden checker 
} from './API';

export class Users {

    async userdetails(id) {
        try {
            return await API.post('admin/user/profile', {
                user_id: id
            });
        } catch (error) {
            return errCheck(error);
        }
    }

    async changePassword(password) {
        try {
            return await API.post('admin/user/change-password', {
                password: password,
            });
        } catch (error) {
            return errCheck(error);
        }
    }


    async profileUpdate(data) {
        try {
            return await API.post('admin/user/profile-update', data);
        } catch (error) {
            return errCheck(error);
        }
    }
}