/* eslint-disable no-unreachable */
const getLength = (password) => {
    return password.length;
}

const getStrength = (password) => {
    var strength = 0;
    if (password.match(/[a-z]+/)) {
        strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
        strength += 1;
    }
    if (password.match(/[0-9]+/)) {
        strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
        strength += 1;
    }
    switch (strength) {
        case 0:
            return 0;
            break;
        case 1:
            return 25;
            break;
        case 2:
            return 50;
            break;
        case 3:
            return 75;
            break;
        case 4:
            return 100;
            break;
    }
}

export {
    getLength,
    getStrength
};