import { Token } from './Token';
import { Faq } from './Faq';
import { inject } from 'vue';
import { Permission } from './Permission';

const tknInst = new Token();
const token = JSON.parse(tknInst.getWithExpiry('user'))?.token;
export class Init {
    ok() {
        this.set_faq_taglist();
        this.set_permissions();
        console.log('Initialized');
    }

    set_faq_taglist() {
        if (token) {
            const faq = new Faq();
            const store = inject('store');
            const permissionService = new Permission();
            let isPermit = permissionService.get_access('customer_center', 'faq');
            let editOnly = permissionService.editOnly(isPermit);
            if (editOnly) {
                faq.taglist().then((res) => {
                    if (res.status === 200) {
                        store.state.faq_taglist = res.data.tags.map((m) => m.faq_category_name);
                        // console.log(store.state.faq_taglist)
                    }
                });
            }
        }
    }

    set_permissions() {
        if (token) {
            const permission = new Permission();
            const store = inject('store');
            let user_id = JSON.parse(tknInst.getWithExpiry('user'))?.user_id;
            let user_type = JSON.parse(tknInst.getWithExpiry('user'))?.user_type;
            if (user_type === 'admin') {
                permission.user_depend({ user_id: user_id }).then((res) => {
                    if (res.status === 200) {
                        store.state.permissions = res.data.permissions;
                        // console.log(store.state.permissions)
                    }
                });
            }
        }
    }
}
