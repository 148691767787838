import {
    // eslint-disable-next-line no-unused-vars
    MPAPI, // for "Content-Type": "multipart/form-data",
    API, // for "Content-Type": "application/json",
    errCheck // for forbidden checker 
} from './API';

export class Faq {
    async create(data) {
        try {
            return await MPAPI.post('admin/faq/add', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async list(data) {
        try {
            return await API.post('admin/faq', data);
        } catch (error) {
            return errCheck(error);
        }
    }
    async taglist(data) {
        try {
            return await API.post('/admin/faq/tags', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async edit(data) {
        try {
            return await API.post('admin/faq/edit', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async view(data) {
        try {
            return await API.post('admin/faq/view', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async delete(data) {
        try {
            return await API.post('admin/faq/delete', data);
        } catch (error) {
            return errCheck(error);
        }
    }
    
    async status(data) {
        try {
            return await API.post('admin/faq/change-status', data);
        } catch (error) {
            return errCheck(error);
        }
    }
}