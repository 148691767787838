<script>
import LoginPage from './authManage/LoginPage.vue';
import ForgotPassword from './authManage/ForgotPassword.vue';
import ResetPassword from './authManage/ResetPassword.vue';
import OtpVerify from './authManage/OtpVerify.vue';
import { useRoute } from 'vue-router';
export default {
    components: {
        LoginPage,
        ForgotPassword,
        ResetPassword,
        OtpVerify,
    },
    setup() {
        const route = useRoute();
        // console.log(Object.keys(route.query).length === 0);
        return { route };
    },
};

/**
 
{
    ft_pass: Forgot Password,
    otp_set: OTP Setting,
    reset_pass: Reset Password
    otp: OtpVerify
}
 
*/
</script>
<template>
    <ForgotPassword v-if="route.query.paCode === 'ft_pass'" />
    <ResetPassword v-if="route.query.paCode === 'reset_pass'" />
    <OtpVerify v-if="route.query.paCode === 'otp'" />
    <LoginPage v-if="Object.keys(route.query).length === 0" />
</template>
