<script>
import { Auth } from '@/service/API';
import { Token } from '@/service/Token';
import { inject, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getLength, getStrength } from '@/validations/ChangePassword';

export default {
    setup() {
        const auth = new Auth();
        const token = new Token();
        const router = useRouter();
        const route = useRoute();
        const store = inject('store');

        if (Object.keys(store.state.user).length === 0) {
            router.push({ path: '/login' });
        }

        const validateModal = ref({ display: false, message: null });
        const data = ref({ otp: '' });
        const errors = ref({});
        const passwordErrors = ref('');
        const conPasswordErrors = ref('');
        const qrModal = ref({ display: false, codeUrl: null, otpPin: '' });
        const otpSetModal = ref({ display: false, message: null });
        const otpReset = ref({ display: false, message: null });
        const defaultReset = ref({ display: false, defPasswordIsInvalid: false, defConPasswordIsInvalid: false, defPasswordVColor: '', defConPasswordVColor: '' });
        const defPassword = ref('');
        const defConPassword = ref('');

        onMounted(() => {
            if (String(route.query.err) === '1') {
                validateModal.value.display = true;
                validateModal.value.message = route.query.msg;
            }
        });

        const close = () => (validateModal.value.display = false);

        const closeOtpSetModal = () => {
            otpSetModal.value.display = false;
        };
        const clear = () => {
            data.value.otp = '';
            qrModal.value.otpPin = '';
        };

        const submitHendler = async () => {
            if (store.state.wrongOtpAttempts >= 5) {
                otpReset.value.display = true;
                otpReset.value.message = '인증 5회 이상 실패하였습니다.OTP재설정 해주세요.';
            } else if (!data.value.otp) {
                errors.value = { otp: 'OTP pin is required' };
            } else {
                OtpVerifying(data.value.otp);
            }
        };

        const defPasswordKeyup = (currentValue) => {
            if (getLength(currentValue) < 1) {
                defaultReset.value.defPasswordIsInvalid = true;
                defaultReset.value.defPasswordVColor = '#f44336';
                passwordErrors.value = 'Password is required';
            } else {
                if (getStrength(currentValue) > 75 && getLength(currentValue) >= 6) {
                    defaultReset.value.defPasswordIsInvalid = false;
                    defaultReset.value.defPasswordVColor = '#00cc1b';
                    passwordErrors.value = 'Password strength is strong!';
                } else if (getStrength(currentValue) > 50) {
                    defaultReset.value.defPasswordIsInvalid = true;
                    defaultReset.value.defPasswordVColor = '#95CD41';
                    passwordErrors.value = 'Password strength is good!';
                } else {
                    defaultReset.value.defPasswordIsInvalid = false;
                    defaultReset.value.defPasswordVColor = '#F8CB2E';
                    passwordErrors.value = 'Password strength is very low!';
                }
            }
        };

        const defConPasswordKeyup = (currentValue) => {
            if (getLength(currentValue) < 1) {
                defaultReset.value.defConPasswordIsInvalid = true;
                defaultReset.value.defConPasswordVColor = '#f44336';
                conPasswordErrors.value = 'Confirm Password is required';
            } else {
                if (currentValue === defPassword.value) {
                    defaultReset.value.defConPasswordIsInvalid = false;
                    defaultReset.value.defConPasswordVColor = '#00cc1b';
                    conPasswordErrors.value = 'Confirm password are matched with password!';
                } else {
                    defaultReset.value.defConPasswordIsInvalid = false;
                    defaultReset.value.defConPasswordVColor = '#f44336';
                    conPasswordErrors.value = 'Confirm password not matching with password!';
                }
            }
        };

        const isDefaultPasswordReset = (otpCheck) => {
            store.state.user = otpCheck;
            otpSetModal.value.display = false;
            otpSetModal.value.message = '';
            defaultReset.value.display = true;
        };

        const defaultReseting = async () => {
            if (defaultReset.value.defConPasswordIsInvalid || defaultReset.value.defPasswordIsInvalid) {
                if (getLength(defConPassword.value) < 1) {
                    defaultReset.value.defConPasswordIsInvalid = true;
                    defaultReset.value.defConPasswordVColor = '#f44336';
                    conPasswordErrors.value = 'Confirm Password is required';
                }
                if (getLength(defPassword.value) < 1) {
                    defaultReset.value.defPasswordIsInvalid = true;
                    defaultReset.value.defPasswordVColor = '#f44336';
                    passwordErrors.value = 'Password is required';
                }
                console.log('defaultReset.value.defConPasswordIsInvalid', defaultReset.value.defConPasswordIsInvalid);
                console.log('defaultReset.value.defPasswordIsInvalid', defaultReset.value.defPasswordIsInvalid);
            } else {
                let passwordChanging = await auth.changePassword({ password: defPassword.value }, store.state.user.access_token);
                // console.log(passwordChanging);
                if (passwordChanging.status === 200) {
                    authenticated(store.state.user);
                } else if (passwordChanging.status === 400) {
                    validateModal.value.display = true;
                    validateModal.value.message = passwordChanging.data.error;
                } else {
                    validateModal.value.display = true;
                    validateModal.value.message = 'Timeout! Try again.';
                }
            }
        };

        const authenticated = async (otpCheck) => {
            let userData = JSON.stringify({
                token: otpCheck.access_token,
                email: otpCheck.email,
                name: otpCheck.name,
                user_id: otpCheck.user_id,
                user_type: otpCheck.user_type,
            });
            let ate_ms = otpCheck.access_token_expiry * 1000;
            token.setWithExpiry('user', userData, ate_ms);
            setTimeout(() => window.location.href = '/', 0);
            // setTimeout(() => router.push({ path: '/' }), 0);
        };

        const OtpVerifying = async (otp, action = '') => {
            let otpCheck = await auth.verifyAuth({
                verify_token: String(otp),
                user_id: String(store.state.user.user_id),
            });

            // console.log(otpCheck);
            if (otpCheck.status === 200) {
                clear();
                // console.log(otpCheck.data);
                if (otpCheck.data.is_default_password_reset === 'n') {
                    isDefaultPasswordReset(otpCheck.data);
                } else {
                    authenticated(otpCheck.data);
                }
            } else if (otpCheck.status === 400 && action === 'resetQR') {
                validateModal.value.display = true;
                validateModal.value.message = otpCheck.data.error;
            } else {
                clear();
                store.state.wrongOtpAttempts = ++store.state.wrongOtpAttempts;
                if (store.state.wrongOtpAttempts >= 5) {
                    otpReset.value.display = true;
                    otpReset.value.message = '인증 5회 이상 실패하였습니다.OTP재설정 해주세요.';
                } else {
                    validateModal.value.display = true;
                    validateModal.value.message = otpCheck.data.error;
                }
            }
        };

        const openQRModal = async () => {
            clear();
            validateModal.value.display = false;
            validateModal.value.message = '';
            otpReset.value.display = false;
            otpReset.value.message = '';
            let qrGenerate = await auth.generateQRCode({ user_id: store.state.user.user_id });
            if (qrGenerate.status === 200) {
                store.state.user.authenticator_secret_key = qrGenerate.data.secret_code;
                qrModal.value.display = true;
                qrModal.value.codeUrl = qrGenerate.data.qr_code_url;
            } else if (qrGenerate.status === 400) {
                validateModal.value.display = true;
                validateModal.value.message = qrGenerate.data.error;
            } else {
                validateModal.value.display = true;
                validateModal.value.message = 'Timeout! Try again.';
            }
        };

        const setOTPSetting = async () => {
            if (!qrModal.value.otpPin) {
                errors.value = { otpPin: 'OTP pin is required' };
            } else {
                errors.value = {};
                let otpSetup = await auth.registerQRCode({
                    secret_code: store.state.user.authenticator_secret_key,
                    user_id: store.state.user.user_id,
                });
                if (otpSetup.status === 200) {
                    OtpVerifying(qrModal.value.otpPin, "resetQR");
                    qrModal.value.display = false;
                    qrModal.value.codeUrl = '';
                    otpSetModal.value.display = true;
                    otpSetModal.value.message = otpSetup.data.message;
                } else if (otpSetup.status === 400) {
                    otpSetModal.value.display = true;
                    otpSetModal.value.message = otpSetup.data.error;
                } else {
                    otpSetModal.value.display = true;
                    otpSetModal.value.message = 'Timeout! Try again.';
                }
            }
        };

        return {
            passwordErrors,
            conPasswordErrors,
            defPasswordKeyup,
            defConPasswordKeyup,
            defPassword,
            defConPassword,
            defaultReseting,
            defaultReset,
            otpReset,
            otpSetModal,
            validateModal,
            data,
            errors,
            close,
            submitHendler,
            openQRModal,
            qrModal,
            closeOtpSetModal,
            setOTPSetting,
        };
    },
};
</script>
<template>
    <Toast />
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <div class="card login-298">
                    <h3 class="welcome-title margin-bottom-30">OTP 설정하기</h3>
                    <div class="p-field p-fluid">
                        <label for="otp">PIN 번호 입력</label>
                        <InputText type="number" :class="{ 'p-invalid': errors.hasOwnProperty('otp') }" id="otp"
                            placeholder="PIN 번호 입력" v-model="data.otp" />
                        <small>{{ errors.hasOwnProperty('otp') ? errors.otp : '' }}</small>
                    </div>
                    <p class="forgot-password"><span @click="openQRModal">OTP 재설정</span></p>
                    <Button label="이메일 인증" @click="submitHendler" class="login-btn" type="button"></Button>
                </div>
            </div>
        </div>
        <Dialog header="Alert!" v-model:visible="validateModal.display" :breakpoints="{ '960px': '75vw' }"
            :style="{ width: '20vw' }" :modal="true">
            <div id="alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ validateModal.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="close()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="OTP 설정하기" v-model:visible="qrModal.display" :breakpoints="{ '960px': '75vw' }"
            :style="{ width: '26vw' }" :modal="true">
            <div id="qr-code-298" class="qr-code">
                <img :src="qrModal.codeUrl" alt="QR Code" />
            </div>
            <div class="p-field p-fluid">
                <small>등록하려면 모바일 장치에 Google OTP를 다운로드하고 QR 코드를 스캔하세요.</small>
            </div>
            <div class="p-field p-fluid">
                <label for="otp-pin">PIN 번호 입력</label>
                <InputText type="number" :class="{ 'p-invalid': errors.hasOwnProperty('otpPin') }" id="otp-pin"
                    placeholder="PIN 번호 입력" v-model="qrModal.otpPin" />
                <small>{{ errors.hasOwnProperty('otpPin') ? errors.otpPin : '' }}</small>
            </div>
            <template #footer>
                <Button label="인증" @click="setOTPSetting()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="otpSetModal.display" :breakpoints="{ '960px': '75vw' }"
            :style="{ width: '20vw' }" :modal="true">
            <div id="otpSetModal-alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ otpSetModal.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="closeOtpSetModal()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="otpReset.display" :breakpoints="{ '960px': '75vw' }"
            :style="{ width: '20vw' }" :modal="true">
            <div id="otpSetModal-alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ otpReset.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="openQRModal()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="defaultReset.display" :breakpoints="{ '960px': '75vw' }"
            :style="{ width: '26vw' }" :modal="true">
            <div id="password-reset-29888">
                <h3 class="welcome-title margin-bottom-30">Please change your default password.</h3>
                <div class="p-field p-fluid">
                    <label for="defaultPassword">비밀번호</label>
                    <Password :class="{ 'p-invalid': defaultReset.defPasswordIsInvalid }" :feedback="false"
                        autocomplete="off" id="defaultPassword" v-model="defPassword" placeholder="**********"
                        @keyup="defPasswordKeyup($event.target.value)" />
                    <small :style="{ color: defaultReset.defPasswordVColor }">{{ passwordErrors }}</small>
                </div>
                <div class="p-field p-fluid">
                    <label for="defaultConfirmPassword">비밀번호 확인</label>
                    <Password :class="{ 'p-invalid': defaultReset.defConPasswordIsInvalid }" :feedback="false"
                        autocomplete="off" id="defaultConfirmPassword" v-model="defConPassword" placeholder="**********"
                        @keyup="defConPasswordKeyup($event.target.value)" />
                    <small :style="{ color: defaultReset.defConPasswordVColor }">{{ conPasswordErrors }}</small>
                </div>
                <Button label="로그인" class="login-btn" type="button" @click="defaultReseting"></Button>
            </div>
        </Dialog>
    </div>
    <!-- <FormLayoutDemoVue /> -->
</template>

<style lang="scss">
#alert-298 {
    .msg-content {
        text-align: center;
    }
}

/* Chrome, Safari, Edge, Opera */
#otp[type='number']::-webkit-outer-spin-button,
#otp[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#otp[type='number'] {
    -moz-appearance: textfield;
}

#password-reset-29888 {
    max-width: 100% !important;
    width: 100% !important;

    .p-field.p-fluid {
        margin-bottom: 2rem !important;

        label {
            display: block !important;
            float: left;
        }

        small {
            text-align: left !important;
            float: left !important;
            width: 100% !important;
            color: #f44336;
        }

        div {
            display: block;
        }
    }

    .p-button {
        width: 100%;
    }
}

.login-body {
    .login-wrapper {
        .login-panel {
            .welcome-title {
                margin: 0;
                font-size: 22px;
                font-weight: 500;
            }

            .margin-bottom-30 {
                margin-bottom: 30px;
            }

            .welcome-subtitle {
                margin: 0;
                font-size: 14px;
                margin-bottom: 15px;
            }

            .forgot-password {
                margin: 0;
                font-size: 10px;
                margin-bottom: 15px;
                text-align: right;

                span {
                    color: #000;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .card.login-298 {
                max-width: 100% !important;
                width: 100% !important;

                .p-field.p-fluid {
                    margin-bottom: 2rem !important;

                    label {
                        display: block !important;
                        float: left;
                    }

                    small {
                        text-align: left !important;
                        float: left !important;
                        width: 100% !important;
                        color: #f44336;
                    }

                    div {
                        display: block;
                    }
                }

                .login-btn {
                    width: 100%;
                }
            }
        }
    }
}
</style>
