import {
    reactive
} from 'vue'

const state = reactive({
    user: {},
    wrongOtpAttempts: 0,
    forgotData: {},
    certification_name: "",
    faq_taglist: [],
    permissions: []
})

export default {
    state
}