import validator from 'validator';

const validate = (data) => {
  let errors = {}

  const {
    email
  } = data;

  if (!validator.isEmail(email)) {
    errors.email = '이메일을 확인해 주세요'
  }
  if (validator.isEmpty(email)) {
    errors.email = '이메일을 입력하시오.'
  }

  return {
    isInvalid: Object.keys(errors).length > 0,
    error: errors
  }
}

export default validate;