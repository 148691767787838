<script>
import { ref, inject } from 'vue';
import { getLength, getStrength } from '@/validations/ChangePassword';
import { Auth } from '@/service/API';
import { useRouter } from 'vue-router';

export default {
    setup() {
        // eslint-disable-next-line no-unused-vars
        const auth = new Auth();
        const router = useRouter();
        const store = inject('store');

        if (Object.keys(store.state.forgotData).length === 0) {
            router.push({ path: '/login' });
        }

        const validateModal = ref({ display: false, message: null });
        const validModal = ref({ display: false, message: null });

        const passwordErrors = ref('');
        const conPasswordErrors = ref('');
        const defaultReset = ref({ display: false, defPasswordIsInvalid: false, defConPasswordIsInvalid: false, defPasswordVColor: '', defConPasswordVColor: '' });

        const defPassword = ref('');
        const defConPassword = ref('');

        const passwordKeyup = (currentValue) => {
            if (getLength(currentValue) < 1) {
                defaultReset.value.defPasswordIsInvalid = true;
                defaultReset.value.defPasswordVColor = '#f44336';
                passwordErrors.value = 'Password is required';
            } else {
                if (getStrength(currentValue) > 75 && getLength(currentValue) >= 6) {
                    defaultReset.value.defPasswordIsInvalid = false;
                    defaultReset.value.defPasswordVColor = '#00cc1b';
                    passwordErrors.value = 'Password strength is strong!';
                } else if (getStrength(currentValue) > 50) {
                    defaultReset.value.defPasswordIsInvalid = true;
                    defaultReset.value.defPasswordVColor = '#95CD41';
                    passwordErrors.value = 'Password strength is good!';
                } else {
                    defaultReset.value.defPasswordIsInvalid = false;
                    defaultReset.value.defPasswordVColor = '#F8CB2E';
                    passwordErrors.value = 'Password strength is very low!';
                }
            }
        };

        const conPasswordKeyup = (currentValue) => {
            if (getLength(currentValue) < 1) {
                defaultReset.value.defConPasswordIsInvalid = true;
                defaultReset.value.defConPasswordVColor = '#f44336';
                conPasswordErrors.value = 'Confirm Password is required';
            } else {
                if (currentValue === defPassword.value) {
                    defaultReset.value.defConPasswordIsInvalid = false;
                    defaultReset.value.defConPasswordVColor = '#00cc1b';
                    conPasswordErrors.value = 'Confirm password are matched with password!';
                } else {
                    defaultReset.value.defConPasswordIsInvalid = false;
                    defaultReset.value.defConPasswordVColor = '#f44336';
                    conPasswordErrors.value = 'Confirm password not matching with password!';
                }
            }
        };

        const defaultReseting = async () => {
            if (defaultReset.value.defConPasswordIsInvalid || defaultReset.value.defPasswordIsInvalid) {
                if (getLength(defConPassword.value) < 1) {
                    defaultReset.value.defConPasswordIsInvalid = true;
                    defaultReset.value.defConPasswordVColor = '#f44336';
                    conPasswordErrors.value = 'Confirm Password is required';
                }
                if (getLength(defPassword.value) < 1) {
                    defaultReset.value.defPasswordIsInvalid = true;
                    defaultReset.value.defPasswordVColor = '#f44336';
                    passwordErrors.value = 'Password is required';
                }
                console.log('defaultReset.value.defConPasswordIsInvalid', defaultReset.value.defConPasswordIsInvalid);
                console.log('defaultReset.value.defPasswordIsInvalid', defaultReset.value.defPasswordIsInvalid);
            } else {
                let passwordChanging = await auth.resetPassword({ password: defPassword.value, email: store.state.forgotData.email, code: store.state.forgotData.code }, store.state.user.access_token);
                // console.log(passwordChanging);
                if (passwordChanging.status === 200) {
                    validateModal.value.display = false;
                    validModal.value.display = true;
                    validModal.value.message = passwordChanging.data.message;
                } else if (passwordChanging.status === 400) {
                    validModal.value.display = false;
                    validateModal.value.display = true;
                    validateModal.value.message = passwordChanging.data.error;
                } else {
                    validateModal.value.display = true;
                    validateModal.value.message = 'Timeout! Try again.';
                }
            }
        };

        const close = () => (validateModal.value.display = false);

        const valid = () => {
            validModal.value.display = false;
            router.push({ path: '/login' });
        };

        return {
            defaultReseting,
            passwordKeyup,
            conPasswordKeyup,
            validModal,
            close,
            validateModal,
            passwordErrors,
            conPasswordErrors,
            defPassword,
            defConPassword,
            valid,
            defaultReset,
        };
    },
};
</script>
<template>
    <Toast />
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <!-- <router-link :to="{ path: '/' }" class="logo">
                    <img src="assets/layout/images/logo.png" alt="freya-layout" />
                </router-link> -->
                <div class="card login-298">
                    <h3 class="welcome-title margin-bottom-30">비밀번호 변경?</h3>
                    <div class="p-field p-fluid">
                        <label for="defaultPassword">비밀번호</label>
                        <Password :class="{ 'p-invalid': defaultReset.defPasswordIsInvalid }" :feedback="false" autocomplete="off" id="defaultPassword" v-model="defPassword" placeholder="**********" @keyup="passwordKeyup($event.target.value)" />
                        <small :style="{ color: defaultReset.defPasswordVColor }">{{ passwordErrors }}</small>
                    </div>
                    <div class="p-field p-fluid">
                        <label for="defaultConfirmPassword">비밀번호 확인</label>
                        <Password
                            :class="{ 'p-invalid': defaultReset.defConPasswordIsInvalid }"
                            :feedback="false"
                            autocomplete="off"
                            id="defaultConfirmPassword"
                            v-model="defConPassword"
                            placeholder="**********"
                            @keyup="conPasswordKeyup($event.target.value)"
                        />
                        <small :style="{ color: defaultReset.defConPasswordVColor }">{{ conPasswordErrors }}</small>
                    </div>
                    <Button label="확인하다" class="login-btn" type="button" @click="defaultReseting"></Button>
                </div>
            </div>
        </div>
        <Dialog header="Alert!" v-model:visible="validateModal.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ validateModal.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="close()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>
        <Dialog header="Alert!" v-model:visible="validModal.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ validModal.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="valid()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>
    </div>
    <!-- <FormLayoutDemoVue /> -->
</template>

<style lang="scss">
#alert-298 {
    .msg-content {
        text-align: center;
    }
}
.login-body {
    .login-wrapper {
        .login-panel {
            .welcome-title {
                margin: 0;
                font-size: 22px;
                font-weight: 500;
            }
            .margin-bottom-30 {
                margin-bottom: 30px;
            }
            .welcome-subtitle {
                margin: 0;
                font-size: 14px;
                margin-bottom: 15px;
            }
            .forgot-password {
                margin: 0;
                font-size: 10px;
                margin-top: 15px;
                a {
                    color: #000;
                    text-decoration: underline;
                }
            }

            .card.login-298 {
                max-width: 100% !important;
                width: 100% !important;
                .p-field.p-fluid {
                    margin-bottom: 2rem !important;
                    label {
                        display: block !important;
                        float: left;
                    }
                    small {
                        text-align: left !important;
                        float: left !important;
                        width: 100% !important;
                    }
                    div {
                        display: block;
                    }
                }
                .login-btn {
                    width: 100%;
                }
            }
        }
    }
}
</style>
