import {
    API, // for "Content-Type": "application/json",
    errCheck, // for forbidden checker
} from './API';
import { inject } from 'vue';
import { Token } from './Token';

export class Permission {
    async user_depend(data) {
        try {
            return await API.post('admin/user/permission', data);
        } catch (error) {
            return errCheck(error);
        }
    }
    
    async view(data) {
        try {
            return await API.post('admin/admin-user/permission/view', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async add(data) {
        try {
            return await API.post('admin/admin-user/permission/add', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async edit(data) {
        try {
            return await API.post('admin/admin-user/permission/edit', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    get_access(menu, section = null) {
        const store = inject('store');
        let items = store.state.permissions;
        const tknInst = new Token();
        let user_type = JSON.parse(tknInst.getWithExpiry('user'))?.user_type;
        let access = '';
        items.forEach((f) => {
            if (f.menu === menu) {
                if (menu === 'homepage' || menu === 'admin_management') {
                    access = f.access_type;
                } else {
                    f.sections.forEach((l) => {
                        l.section === section ? (access = l.access_type) : null;
                    });
                }
            }
        });

        return {
            access,
            user_type,
        };
    }

    both(permit) {
        return permit.user_type === 'super_admin' ? true : permit.access === 'edit' || permit.access === 'read' ? true : false;
    }

    editOnly(permit) {
        return permit.user_type === 'super_admin' ? true : permit.access === 'edit' ? true : false;
    }
}
