/* eslint-disable no-unused-vars */
import axios from 'axios';
import { baseURL, XAPIKEY } from './Base';
import { Token } from './Token';

const tknInst = new Token();
const token = JSON.parse(tknInst.getWithExpiry('user'))?.token;
// console.log(token);

export const UAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': XAPIKEY,
    },
});

export const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': XAPIKEY,
        token: token,
    },
});

export const MPAPI = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': XAPIKEY,
        token: token,
    },
});

export const errCheck = (er) => {
    if (er.response.status === 400) return er.response;
    else if (er.response.status === 403) {
        localStorage.removeItem('user');
        window.location.href = '/login';
    } else console.log(er.response);
};

export class Auth {
    async check(data) {
        try {
            return await UAPI.post('admin/user/login', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async forgotPassword(data) {
        try {
            return await UAPI.post('admin/user/forgot-password', data);
        } catch (error) {
            return errCheck(error);
        }
    }
    async resetPassword(data) {
        try {
            return await UAPI.post('admin/user/reset-password', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async registerQRCode(data) {
        try {
            return await UAPI.post('admin/user/register-qr-code', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async generateQRCode(data) {
        try {
            return await UAPI.post('admin/user/generate-qr-code', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async verifyAuth(data) {
        try {
            return await UAPI.post('admin/user/verify-auth', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async changePassword(data, token) {
        try {
            var config = {
                'Content-Type': 'application/json',
                'x-api-key': XAPIKEY,
                token: token,
            };
            return await axios.post(`${baseURL}admin/user/change-password`, data, {
                headers: config,
            });
        } catch (error) {
            return errCheck(error);
        }
    }
}
