<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active': sidebarActive }" @click="onMenuClick" @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img :src="origin + '/assets/layout/images/logo-freya-single.svg'" alt="" />
                <strong style="padding-left: 15px; font-size: 20px">{{ title }}</strong>
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu
                class="layout-menu"
                :items="menus"
                :layoutMode="layoutMode"
                :parentMenuItemActive="true"
                :menuActive="menuActive"
                :mobileMenuActive="mobileMenuActive"
                :root="true"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />

            <div @click="logout">
                <div>
                    <span class="logout-btn">
                        <i class="pi pi-sign-out"></i>
                        {{ mouse_over ? 'Logout' : '' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.logout-btn {
    position: absolute;
    bottom: 20px;
    display: block;
    width: 100%;
    padding: 0 20px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.logout-btn:hover {
    color: var(--primary-color);
}

.logout-btn i {
    font-size: 24px;
    vertical-align: middle;
}
</style>

<script>
import AppSubmenu from './AppSubmenu';
import { Token } from '@/service/Token';
import { Permission } from '@/service/Permission';
import { Users } from '@/service/Users';
import { inject } from 'vue';

export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'toggle-menu', 'sidebar-mouse-over', 'sidebar-mouse-leave'],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
    },
    data() {
        return {
            origin: window.location.origin,
            title: '',
            user_type: '',
            user_id: '',
            authority_id: '',
            mouse_over: false,
            menus: [],
            admin_user: [],
            super_admin: [
                //dashboard
                { label: '계기반', icon: 'pi pi-home', to: '/', name: 'homepage' },
                //Membership Management
                {
                    name: 'membership_management',
                    label: '회원관리',
                    icon: 'pi pi-user',
                    items: [
                        { label: '기업관리', icon: 'pi pi-tags', to: '/CompanyManagement', name: 'company_management' },
                        { label: '회원관리', icon: 'pi pi-inbox', to: '/MemberManagement', name: 'member_management' },
                        { label: '휴면회원 관리', icon: 'pi pi-comments', to: '/DormantManagement', name: 'dormant_member_management' },
                    ],
                },
                //Admin Management
                { label: '관리자 관리', icon: 'pi pi-star', to: '/Admin-Management', name: 'admin_management' },
                //License Management
                {
                    name: 'license_management',
                    label: '라이선스 관리',
                    icon: 'pi pi-briefcase',
                    items: [
                        { label: '결제관리', icon: 'pi pi-money-bill', to: '/PaymentManagement', name: 'payment_management' },
                        { label: '패키지 관리', icon: 'pi pi-clone', to: '/PackageManagement', name: 'package_management' },
                    ],
                },
                //Information Security Certification Management
                {
                    name: 'information_security_certification_management',
                    label: '정보보호 인증관리',
                    icon: 'pi pi-desktop',
                    items: [
                        { label: '관리 진단 설정', icon: 'pi pi-tags', to: '/diagnosis-management-settings', name: 'diagnosis_management_settings' },
                        { label: '기술적 진단 설정', icon: 'pi pi-inbox', to: '/technical-diagnosis-settings', name: 'technical_diagnosis_settings' },
                        { label: '전문 진단 상세 페이지', icon: 'pi pi-comments', to: '/technical-diagnosis-Detail', name: 'technical_diagnosis_settings' },
                        { label: '정보보호 인증설정', icon: 'pi pi-map', to: '/information-security-authentication-settings', name: 'information_security_authentication_settings' },
                        { label: '증적 설정', icon: 'pi pi-table', to: '/evidence-settings', name: 'evidence_settings' },
                        { label: '위험 진단 관리', icon: 'pi pi-share-alt', to: '/Risk-Evaluation-ManagementList', name: 'risk_evaluation_management' },
                    ],
                },
                //Customer Service
                {
                    name: 'customer_center',
                    label: '고객센터',
                    icon: 'pi pi-users',
                    items: [
                        { label: '공지사항', icon: 'pi pi-tags', to: '/notice', name: 'notice' },
                        { label: '자주 묻는 질문', icon: 'pi pi-inbox', to: '/faq', name: 'faq' },
                        { label: '1:1 문의', icon: 'pi pi-comments', to: '/inquiry', name: 'one_one_inquiry' },
                    ],
                },
            ],
        };
    },
    created() {
        this.tknInst = new Token();
        this.permission = new Permission();
        this.users = new Users();
        this.user_type = JSON.parse(this.tknInst.getWithExpiry('user'))?.user_type;
        this.user_id = JSON.parse(this.tknInst.getWithExpiry('user'))?.user_id;
        this.store = inject('store');
    },
    async mounted() {
        console.log('mount -- ' + this.user_type);
        if (this.user_type === 'super_admin') {
            this.menus = this.super_admin;
        } else if (this.user_type === 'admin') {
            this.permission.user_depend({ user_id: this.user_id }).then((res) => {
                this.store.state.permissions = res.data.permissions;
                // console.log(this.store.state.permissions);
                res.data.permissions.forEach((item) => {
                    if (item.menu === 'homepage' && item.access_type !== undefined && item.access_type !== 'none') {
                        this.menus.push({ label: '계기반', icon: 'pi pi-home', to: '/', name: 'homepage', sortValue: 1 });
                    }

                    if (item.menu === 'admin_management' && item.access_type !== undefined && item.access_type !== 'none') {
                        this.menus.push({ label: '관리자 관리', icon: 'pi pi-star', to: '/Admin-Management', name: 'admin_management', sortValue: 3 });
                    }

                    if (item.menu === 'membership_management') {
                        var member_management_sections = [];
                        item.sections.forEach((mms) => {
                            if (mms.section === 'company_management' && mms.access_type !== 'none') {
                                member_management_sections.push({ label: '기업관리', icon: 'pi pi-tags', to: '/CompanyManagement', name: 'company_management', sortValue: 1 });
                            }
                            if (mms.section === 'member_management' && mms.access_type !== 'none') {
                                member_management_sections.push({ label: '회원관리', icon: 'pi pi-inbox', to: '/MemberManagement', name: 'member_management', sortValue: 2 });
                            }
                            if (mms.section === 'dormant_member_management' && mms.access_type !== 'none') {
                                member_management_sections.push({ label: '휴면회원 관리', icon: 'pi pi-comments', to: '/DormantManagement', name: 'dormant_member_management', sortValue: 3 });
                            }
                        });
                        member_management_sections.sort((a, b) => a.sortValue - b.sortValue);

                        if (item.sections.filter((f) => f.access_type !== 'none').length > 0) {
                            this.menus.push({
                                name: 'membership_management',
                                label: '회원관리',
                                icon: 'pi pi-user',
                                sortValue: 2,
                                items: member_management_sections,
                            });
                        }
                    }

                    if (item.menu === 'license_management') {
                        var license_management_sections = [];
                        item.sections.forEach((lms) => {
                            if (lms.section === 'payment_management' && lms.access_type !== 'none') {
                                license_management_sections.push({ label: '결제관리', icon: 'pi pi-money-bill', to: '/PaymentManagement', name: 'payment_management', sortValue: 1 });
                            }
                            if (lms.section === 'package_management' && lms.access_type !== 'none') {
                                license_management_sections.push({ label: '패키지 관리', icon: 'pi pi-clone', to: '/PackageManagement', name: 'package_management', sortValue: 2 });
                            }
                        });
                        license_management_sections.sort((a, b) => a.sortValue - b.sortValue);
                        if (item.sections.filter((f) => f.access_type !== 'none').length > 0) {
                            this.menus.push({
                                name: 'license_management',
                                label: '라이선스 관리',
                                icon: 'pi pi-briefcase',
                                sortValue: 4,
                                items: license_management_sections,
                            });
                        }
                    }

                    if (item.menu === 'information_security_certification_management') {
                        var information_security_sections = [];
                        item.sections.forEach((iss) => {
                            if (iss.section === 'diagnosis_management_settings' && iss.access_type !== 'none') {
                                information_security_sections.push({ label: '관리 진단 설정', icon: 'pi pi-tags', to: '/diagnosis-management-settings', name: 'diagnosis_management_settings', sortValue: 1 });
                            }
                            if (iss.section === 'technical_diagnosis_settings' && iss.access_type !== 'none') {
                                information_security_sections.push({ label: '기술적 진단 설정', icon: 'pi pi-inbox', to: '/technical-diagnosis-settings', name: 'technical_diagnosis_settings', sortValue: 2 });
                                information_security_sections.push({ label: '전문 진단 상세 페이지', icon: 'pi pi-comments', to: '/technical-diagnosis-Detail', name: 'technical_diagnosis_settings', sortValue: 3 });
                            }
                            if (iss.section === 'information_security_authentication_settings' && iss.access_type !== 'none') {
                                information_security_sections.push({ label: '정보보호 인증설정', icon: 'pi pi-map', to: '/information-security-authentication-settings', name: 'information_security_authentication_settings', sortValue: 4 });
                            }
                            if (iss.section === 'evidence_settings' && iss.access_type !== 'none') {
                                information_security_sections.push({ label: '증적 설정', icon: 'pi pi-table', to: '/evidence-settings', name: 'evidence_settings', sortValue: 5 });
                            }
                            if (iss.section === 'risk_evaluation_management' && iss.access_type !== 'none') {
                                information_security_sections.push({ label: '위험 진단 관리', icon: 'pi pi-share-alt', to: '/Risk-Evaluation-ManagementList', name: 'risk_evaluation_management', sortValue: 6 });
                            }
                        });
                        information_security_sections.sort((a, b) => a.sortValue - b.sortValue);
                        if (item.sections.filter((f) => f.access_type !== 'none').length > 0) {
                            this.menus.push({
                                name: 'information_security_certification_management',
                                label: '정보보호 인증관리',
                                icon: 'pi pi-desktop',
                                sortValue: 5,
                                items: information_security_sections,
                            });
                        }
                    }

                    if (item.menu === 'customer_center') {
                        var customer_center_sections = [];
                        item.sections.forEach((ccs) => {
                            if (ccs.section === 'notice' && ccs.access_type !== 'none') {
                                customer_center_sections.push({ label: '공지사항', icon: 'pi pi-tags', to: '/notice', name: 'notice', sortValue: 1 });
                            }
                            if (ccs.section === 'faq' && ccs.access_type !== 'none') {
                                customer_center_sections.push({ label: '자주 묻는 질문', icon: 'pi pi-inbox', to: '/faq', name: 'faq', sortValue: 2 });
                            }
                            if (ccs.section === 'one_one_inquiry' && ccs.access_type !== 'none') {
                                customer_center_sections.push({ label: '1:1 문의', icon: 'pi pi-comments', to: '/inquiry', name: 'one_one_inquiry', sortValue: 3 });
                            }
                        });
                        customer_center_sections.sort((a, b) => a.sortValue - b.sortValue);

                        if (item.sections.filter((f) => f.access_type !== 'none').length > 0) {
                            this.menus.push({
                                name: 'customer_center',
                                label: '고객센터',
                                icon: 'pi pi-users',
                                sortValue: 6,
                                items: customer_center_sections,
                            });
                        }
                    }
                });
                this.menus.sort((a, b) => a.sortValue - b.sortValue);
                // console.log(this.menus);
            });
        }
    },
    methods: {
        logout() {
            // console.log('logout method clicked');
            localStorage.clear();
            this.$router.push({ name: 'login' });
        },
        onSidebarMouseOver() {
            if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
                this.$emit('sidebar-mouse-over');
                this.title = 'Athena';
                this.mouse_over = true;
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit('sidebar-mouse-leave');
                    this.title = '';
                    this.mouse_over = false;
                }, 250);
            }
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
    components: { AppSubmenu },
};
</script>
