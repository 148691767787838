<script>
import { ref } from 'vue';
import validateLogin from '@/validations/validateLogin';
import { Auth } from '@/service/API';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { Token } from '@/service/Token';
import { getLength, getStrength } from '@/validations/ChangePassword';

export default {
    setup() {
        const auth = new Auth();
        const store = inject('store');
        const router = useRouter();
        const token = new Token();

        const validateModal = ref({ display: false, message: null });
        const otpModal = ref({ display: false, message: null });
        const otpSetModal = ref({ display: false, message: null });
        const passwordExp = ref({ display: false, message: null });
        const qrModal = ref({ display: false, codeUrl: null, otpPin: '' });
        const data = ref({ email: '', password: '' });
        const errors = ref({});
        const defaultReset = ref({ display: false, defPasswordIsInvalid: false, defConPasswordIsInvalid: false, defPasswordVColor: '', defConPasswordVColor: '' });
        const defPassword = ref('');
        const defConPassword = ref('');
        const passwordErrors = ref('');
        const conPasswordErrors = ref('');
        const verification = ref({ display: false, codeIsInvalid: false, code: '', codeError: '', conCodeIsInvalid: false, conCode: '', conCodeError: '' });
        const fiveAttempts = ref(false);

        const serverValidation = (err) => {
            if (typeof err === 'object') {
                var emailValidation = err['login_user'] !== undefined ? { email: err.login_user } : {};
                var passwordValidation = err['password'] !== undefined ? { password: err.password } : {};
                errors.value = { ...emailValidation, ...passwordValidation };
            } else {
                if (parseInt(err.replace(/[^\d.]/g, '')) === 5) {
                    // sending code on this email
                    fiveAttempts.value = true;
                    validateModal.value.display = true;
                    validateModal.value.message = err;
                } else {
                    validateModal.value.display = true;
                    validateModal.value.message = err;
                }
            }
        };
        const sendCodeforReset = async () => {
            let sendMail = await auth.forgotPassword({ email: data.value.email });
            if (sendMail.status === 200) {
                store.state.forgotData = sendMail.data;
                verification.value.display = true;
            } else if (sendMail.status === 400) {
                validateModal.value.display = true;
                validateModal.value.message = sendMail.data.error;
            } else {
                validateModal.value.display = true;
                validateModal.value.message = 'Timeout! Try again.';
            }
        };

        const confirmEmailVerify = () => {
            if (verification.value.code.length < 1 || verification.value.conCode.length < 1) {
                if (verification.value.code.length < 1) {
                    verification.value.codeIsInvalid = true;
                    verification.value.codeError = 'Verification code is required!';
                }
                if (verification.value.conCode.length < 1) {
                    verification.value.conCodeIsInvalid = true;
                    verification.value.conCodeError = 'Confirm verification code is required!';
                }
            } else {
                if (verification.value.code === verification.value.conCode) {
                    verification.value.codeIsInvalid = false;
                    verification.value.conCodeIsInvalid = false;
                    store.state.forgotData['code'] = verification.value.code;
                    router.push({ path: '/login', query: { paCode: 'reset_pass' } });
                } else {
                    verification.value.conCodeIsInvalid = true;
                    verification.value.conCodeError = 'Confirm verification code not matching!';
                }
            }
        };

        const loginProcess = (user) => {
            // console.log(user);
            if (user.is_password_expired === 'yes') {
                store.state.user = user;
                passwordExp.value.display = true;
                passwordExp.value.message = 'Your password has expired!';
            } else if (user.is_authenticator_enable === 'y') {
                if (user.login_otp_enabled === 'no') {
                    otpModal.value.display = true;
                    otpModal.value.message = 'OTP 설정이 필요합니다.';
                    store.state.user = user;
                } else if (user.login_otp_enabled === 'yes') {
                    store.state.user = user;
                    router.push({ path: '/login', query: { paCode: 'otp' } });
                }
            } else if (user.is_authenticator_enable === 'n') {
                if (user.is_default_password_reset === 'n') {
                    isDefaultPasswordReset(user);
                } else {
                    authenticated(user);
                }
            }
        };

        const authenticated = async (user) => {
            let userData = JSON.stringify({
                token: user.access_token,
                email: user.email,
                name: user.name,
                user_id: user.user_id,
                user_type: user.user_type,
            });
            let ate_ms = user.access_token_expiry * 1000;
            token.setWithExpiry('user', userData, ate_ms);
            setTimeout(() => (window.location.href = '/'), 0);
            // setTimeout(() => router.push({ path: '/' }), 0);
        };

        const defPasswordKeyup = (currentValue) => {
            if (getLength(currentValue) < 1) {
                defaultReset.value.defPasswordIsInvalid = true;
                defaultReset.value.defPasswordVColor = '#f44336';
                passwordErrors.value = 'Password is required';
            } else {
                if (getStrength(currentValue) > 75 && getLength(currentValue) >= 6) {
                    defaultReset.value.defPasswordIsInvalid = false;
                    defaultReset.value.defPasswordVColor = '#00cc1b';
                    passwordErrors.value = 'Password strength is strong!';
                } else if (getStrength(currentValue) > 50) {
                    defaultReset.value.defPasswordIsInvalid = true;
                    defaultReset.value.defPasswordVColor = '#95CD41';
                    passwordErrors.value = 'Password strength is good!';
                } else {
                    defaultReset.value.defPasswordIsInvalid = false;
                    defaultReset.value.defPasswordVColor = '#F8CB2E';
                    passwordErrors.value = 'Password strength is very low!';
                }
            }
        };

        const defConPasswordKeyup = (currentValue) => {
            if (getLength(currentValue) < 1) {
                defaultReset.value.defConPasswordIsInvalid = true;
                defaultReset.value.defConPasswordVColor = '#f44336';
                conPasswordErrors.value = 'Confirm Password is required';
            } else {
                if (currentValue === defPassword.value) {
                    defaultReset.value.defConPasswordIsInvalid = false;
                    defaultReset.value.defConPasswordVColor = '#00cc1b';
                    conPasswordErrors.value = 'Confirm password are matched with password!';
                } else {
                    defaultReset.value.defConPasswordIsInvalid = false;
                    defaultReset.value.defConPasswordVColor = '#f44336';
                    conPasswordErrors.value = 'Confirm password not matching with password!';
                }
            }
        };

        const isDefaultPasswordReset = (otpCheck) => {
            store.state.user = otpCheck;
            otpSetModal.value.display = false;
            otpSetModal.value.message = '';
            defaultReset.value.display = true;
        };

        const defaultReseting = async () => {
            if (defaultReset.value.defConPasswordIsInvalid || defaultReset.value.defPasswordIsInvalid) {
                if (getLength(defConPassword.value) < 1) {
                    defaultReset.value.defConPasswordIsInvalid = true;
                    defaultReset.value.defConPasswordVColor = '#f44336';
                    conPasswordErrors.value = 'Confirm Password is required';
                }
                if (getLength(defPassword.value) < 1) {
                    defaultReset.value.defPasswordIsInvalid = true;
                    defaultReset.value.defPasswordVColor = '#f44336';
                    passwordErrors.value = 'Password is required';
                }
                console.log('defaultReset.value.defConPasswordIsInvalid', defaultReset.value.defConPasswordIsInvalid);
                console.log('defaultReset.value.defPasswordIsInvalid', defaultReset.value.defPasswordIsInvalid);
            } else {
                let passwordChanging = await auth.changePassword({ password: defPassword.value }, store.state.user.access_token);
                // console.log(passwordChanging);
                if (passwordChanging.status === 200) {
                    authenticated(store.state.user);
                } else if (passwordChanging.status === 400) {
                    validateModal.value.display = true;
                    validateModal.value.message = passwordChanging.data.error;
                } else {
                    validateModal.value.display = true;
                    validateModal.value.message = 'Timeout! Try again.';
                }
            }
        };

        const submitHendler = async () => {
            var validation = validateLogin(data.value);
            if (validation.isInvalid) {
                errors.value = validation.error;
            } else {
                errors.value = {};
                let authority = await auth.check({ login_user: data.value.email, login_password: data.value.password });
                if (authority.status === 400) {
                    serverValidation(authority.data.error);
                } else {
                    loginProcess(authority.data);
                    console.log(authority.data);
                }
            }
        };

        const close = async () => {
            if (fiveAttempts.value) {
                await sendCodeforReset();
            }
            validateModal.value.display = false;
        };

        const closeOtpSetModal = () => (otpSetModal.value.display = false);

        const passwordExpClose = () => {
            router.push({ path: '/login', query: { paCode: 'ft_pass' } });
        };

        const openQRModal = () => {
            otpModal.value.display = false;
            otpModal.value.message = '';
            qrModal.value.display = true;
            qrModal.value.codeUrl = store.state.user.qr_code_url;
        };

        const setOTPSetting = async () => {
            if (!qrModal.value.otpPin) {
                errors.value = { otpPin: 'OTP pin is required' };
            } else {
                errors.value = {};
                let otpSetup = await auth.registerQRCode({
                    secret_code: store.state.user.authenticator_secret_key,
                    user_id: store.state.user.user_id,
                });
                if (otpSetup.status === 200) {
                    OtpVerifying();
                    otpSetModal.value.display = true;
                    otpSetModal.value.message = otpSetup.data.message;
                } else if (otpSetup.status === 400) {
                    otpSetModal.value.display = true;
                    otpSetModal.value.message = otpSetup.data.error;
                } else {
                    otpSetModal.value.display = true;
                    otpSetModal.value.message = 'Timeout! Try again.';
                }
            }
        };

        const OtpVerifying = async () => {
            let otpCheck = await auth.verifyAuth({
                verify_token: qrModal.value.otpPin,
                user_id: store.state.user.user_id,
            });
            if (otpCheck.status === 200) {
                store.state.user = otpCheck.data;
                router.push({ path: '/' });
            } else {
                store.state.wrongOtpAttempts = ++store.state.wrongOtpAttempts;
                router.push({ path: '/login', query: { paCode: 'otp', msg: otpCheck.data.error, err: 1 } });
            }
        };

        return {
            passwordErrors,
            conPasswordErrors,
            defPasswordKeyup,
            defConPasswordKeyup,
            defPassword,
            defConPassword,
            defaultReseting,
            defaultReset,
            passwordExp,
            passwordExpClose,
            validateModal,
            data,
            submitHendler,
            errors,
            close,
            qrModal,
            otpSetModal,
            otpModal,
            openQRModal,
            setOTPSetting,
            closeOtpSetModal,
            verification,
            confirmEmailVerify,
            fiveAttempts,
        };
    },
};
</script>
<template>
    <Toast />
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <router-link :to="{ path: '/' }" class="logo">
                    <img src="assets/layout/images/logo.png" alt="freya-layout" />
                </router-link>
                <div class="card login-298">
                    <h3 class="welcome-title">관리자님 환영합니다</h3>
                    <br />
                    <p class="welcome-subtitle">계정에 로그인하십시오.</p>
                    <div class="p-field p-fluid">
                        <label for="username">이메일</label>
                        <InputText :class="{ 'p-invalid': errors.hasOwnProperty('email') }" id="email" placeholder="이메일 입력" v-model="data.email" />
                        <small>{{ errors.hasOwnProperty('email') ? errors.email : '' }}</small>
                    </div>
                    <div class="p-field p-fluid">
                        <label for="password">비밀번호</label>
                        <Password :class="{ 'p-invalid': errors.hasOwnProperty('password') }" :feedback="false" id="password" v-model="data.password" placeholder="**********" />
                        <small>{{ errors.hasOwnProperty('password') ? errors.password : '' }}</small>
                    </div>
                    <Button label="로그인" class="login-btn" type="button" @click="submitHendler"></Button>
                    <p class="forgot-password">비밀번호를 잊으셨나요? <router-link :to="{ path: '/login', query: { paCode: 'ft_pass' } }">지금 재설정</router-link></p>
                </div>
            </div>
        </div>

        <Dialog header="Alert!" v-model:visible="validateModal.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ validateModal.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="close()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="otpModal.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="otpModal-alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ otpModal.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="openQRModal()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="otpSetModal.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="otpSetModal-alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ otpSetModal.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="closeOtpSetModal()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="passwordExp.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="otpSetModal-alert-298" class="p-field p-fluid">
                <p class="msg-content">
                    {{ passwordExp.message }}
                </p>
            </div>
            <template #footer>
                <Button label="Reset Password" @click="passwordExpClose()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="OTP 설정하기" v-model:visible="qrModal.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '26vw' }" :modal="true">
            <div id="qr-code-298" class="qr-code">
                <img :src="qrModal.codeUrl" alt="QR Code" />
            </div>
            <div class="p-field p-fluid">
                <small>등록하려면 모바일 장치에 Google OTP를 다운로드하고 QR 코드를 스캔하세요.</small>
            </div>
            <div class="p-field p-fluid">
                <label for="otp-pin">PIN 번호 입력</label>
                <InputText type="number" :class="{ 'p-invalid': errors.hasOwnProperty('otpPin') }" id="otp-pin" placeholder="PIN 번호 입력" v-model="qrModal.otpPin" />
                <small>{{ errors.hasOwnProperty('otpPin') ? errors.otpPin : '' }}</small>
            </div>
            <template #footer>
                <Button label="인증" @click="setOTPSetting()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="defaultReset.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '26vw' }" :modal="true">
            <div id="password-reset-29888">
                <h3 class="welcome-title margin-bottom-30">Please change your default password.</h3>
                <div class="p-field p-fluid">
                    <label for="defaultPassword">비밀번호</label>
                    <Password :class="{ 'p-invalid': defaultReset.defPasswordIsInvalid }" :feedback="false" autocomplete="off" id="defaultPassword" v-model="defPassword" placeholder="**********" @keyup="defPasswordKeyup($event.target.value)" />
                    <small :style="{ color: defaultReset.defPasswordVColor }">{{ passwordErrors }}</small>
                </div>
                <div class="p-field p-fluid">
                    <label for="defaultConfirmPassword">비밀번호 확인</label>
                    <Password
                        :class="{ 'p-invalid': defaultReset.defConPasswordIsInvalid }"
                        :feedback="false"
                        autocomplete="off"
                        id="defaultConfirmPassword"
                        v-model="defConPassword"
                        placeholder="**********"
                        @keyup="defConPasswordKeyup($event.target.value)"
                    />
                    <small :style="{ color: defaultReset.defConPasswordVColor }">{{ conPasswordErrors }}</small>
                </div>
                <Button label="로그인" class="login-btn" type="button" @click="defaultReseting"></Button>
            </div>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="verification.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '26vw' }" :modal="true">
            <div id="password-reset-2988">
                <h3 class="welcome-title margin-bottom-30">이메일 확인?</h3>
                <div class="p-field p-fluid">
                    <label for="verify-code">코드 확인</label>
                    <InputText :class="{ 'p-invalid': verification.codeIsInvalid }" id="verify-code" v-model="verification.code" placeholder="**********" />
                    <small>{{ verification.codeError }}</small>
                </div>
                <div class="p-field p-fluid">
                    <label for="verify-confirm-code">인증 코드 재입력</label>
                    <InputText :class="{ 'p-invalid': verification.conCodeIsInvalid }" id="verify-confirm-code" v-model="verification.conCode" placeholder="**********" />
                    <small>{{ verification.conCodeError }}</small>
                </div>
                <Button label="확인" class="login-btn" type="button" @click="confirmEmailVerify"></Button>
            </div>
        </Dialog>
    </div>
    <!-- <FormLayoutDemoVue /> -->
</template>

<style lang="scss">
#alert-298 {
    .msg-content {
        text-align: center;
    }
}

#otpModal-alert-298 {
    .msg-content {
        text-align: center;
    }
}

#otpSetModal-alert-298 {
    .msg-content {
        text-align: center;
    }
}

#qr-code-298 {
    img {
        width: 100%;
    }
}

/* Chrome, Safari, Edge, Opera */
#otp-pin::-webkit-outer-spin-button,
#otp-pin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#otp-pin[type='number'] {
    -moz-appearance: textfield;
}

.login-body {
    .login-wrapper {
        .login-panel {
            .welcome-title {
                margin: 0;
                font-size: 22px;
                font-weight: 500;
            }

            .welcome-subtitle {
                margin: 0;
                font-size: 14px;
                margin-bottom: 15px;
            }

            .forgot-password {
                margin: 0;
                font-size: 10px;

                // margin-top: 15px;
                a {
                    color: #000;
                    text-decoration: underline;
                }
            }

            .card.login-298 {
                max-width: 100% !important;
                width: 100% !important;

                .p-field.p-fluid {
                    margin-bottom: 2rem !important;

                    label {
                        display: block !important;
                        float: left;
                    }

                    small {
                        text-align: left !important;
                        float: left !important;
                        width: 100% !important;
                    }

                    div {
                        display: block;
                    }
                }

                .login-btn {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>
