<script>
import { ref, inject } from 'vue';
import validate from '@/validations/ForgotPassword';
import { Auth } from '@/service/API';
import { useRouter } from 'vue-router';
export default {
    setup() {
        const modal = ref({ display: false, message: '' });
        const data = ref({ email: '' });
        const errors = ref({});
        const auth = new Auth();
        const store = inject('store');
        const router = useRouter();

        const verification = ref({ display: false, codeIsInvalid: false, code: '', codeError: '', conCodeIsInvalid: false, conCode: '', conCodeError: '' });

        const close = () => (modal.value.display = false);

        const submitHendler = async () => {
            var validation = validate(data.value);
            if (validation.isInvalid) {
                errors.value = validation.error;
            } else {
                errors.value = {};
                console.log(errors.value);
                modal.value.display = false;

                let sendMail = await auth.forgotPassword({ email: data.value.email });
                if (sendMail.status === 200) {
                    store.state.forgotData = sendMail.data;
                    verification.value.display = true;
                } else if (sendMail.status === 400) {
                    modal.value.display = true;
                    modal.value.message = sendMail.data.error;
                } else {
                    modal.value.display = true;
                    modal.value.message = 'Timeout! Try again.';
                }
            }
        };

        const confirmEmailVerify = () => {
            if (verification.value.code.length < 1 || verification.value.conCode.length < 1) {
                if (verification.value.code.length < 1) {
                    verification.value.codeIsInvalid = true;
                    verification.value.codeError = 'Verification code is required!';
                }
                if (verification.value.conCode.length < 1) {
                    verification.value.conCodeIsInvalid = true;
                    verification.value.conCodeError = 'Confirm verification code is required!';
                }
            } else {
                if (verification.value.code === verification.value.conCode) {
                    verification.value.codeIsInvalid = false;
                    verification.value.conCodeIsInvalid = false;
                    store.state.forgotData['code'] = verification.value.code;
                    router.push({ path: '/login', query: { paCode: 'reset_pass' } });
                } else {
                    verification.value.conCodeIsInvalid = true;
                    verification.value.conCodeError = 'Confirm verification code not matching!';
                }
            }
        };

        return { verification, confirmEmailVerify, modal, data, errors, close, submitHendler };
    },
};
</script>
<template>
    <Toast />
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <div class="card login-298">
                    <h3 class="welcome-title margin-bottom-30">비밀번호를 잊으셨나요?</h3>
                    <div class="p-field p-fluid">
                        <label for="username">이메일</label>
                        <InputText :class="{ 'p-invalid': errors.hasOwnProperty('email') }" id="email" placeholder="이메일 입력" v-model="data.email" />
                        <small>{{ errors.hasOwnProperty('email') ? errors.email : '' }}</small>
                    </div>

                    <Button label="이메일 인증" @click="submitHendler" class="login-btn" type="button"></Button>
                </div>
            </div>
        </div>

        <Dialog header="Alert!" v-model:visible="modal.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="alert-298" class="p-field p-fluid">
                <p class="msg-content">{{ modal.message }}</p>
            </div>
            <template #footer>
                <Button label="확인" @click="close()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>

        <Dialog header="Alert!" v-model:visible="verification.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '26vw' }" :modal="true">
            <div id="password-reset-2988">
                <h3 class="welcome-title margin-bottom-30">이메일 확인?</h3>
                <div class="p-field p-fluid">
                    <label for="verify-code">코드 확인</label>
                    <InputText :class="{ 'p-invalid': verification.codeIsInvalid }" id="verify-code" v-model="verification.code" placeholder="**********" />
                    <small>{{ verification.codeError }}</small>
                </div>
                <div class="p-field p-fluid">
                    <label for="verify-confirm-code">인증 코드 재입력</label>
                    <InputText :class="{ 'p-invalid': verification.conCodeIsInvalid }" id="verify-confirm-code" v-model="verification.conCode" placeholder="**********" />
                    <small>{{ verification.conCodeError }}</small>
                </div>
                <Button label="확인" class="login-btn" type="button" @click="confirmEmailVerify"></Button>
            </div>
        </Dialog>
    </div>
    <!-- <FormLayoutDemoVue /> -->
</template>

<style lang="scss">
#alert-298 {
    .msg-content {
        text-align: center;
    }
}

#password-reset-2988 {
    max-width: 100% !important;
    width: 100% !important;
    .p-field.p-fluid {
        margin-bottom: 2rem !important;
        label {
            display: block !important;
            float: left;
        }
        small {
            text-align: left !important;
            float: left !important;
            width: 100% !important;
            color: #f44336;
        }
        div {
            display: block;
        }
    }
    .p-button {
        width: 100%;
    }
}
.login-body {
    .login-wrapper {
        .login-panel {
            .welcome-title {
                margin: 0;
                font-size: 22px;
                font-weight: 500;
            }
            .margin-bottom-30 {
                margin-bottom: 30px;
            }
            .welcome-subtitle {
                margin: 0;
                font-size: 14px;
                margin-bottom: 15px;
            }
            .forgot-password {
                margin: 0;
                font-size: 10px;
                margin-top: 15px;
                a {
                    color: #000;
                    text-decoration: underline;
                }
            }

            .card.login-298 {
                max-width: 100% !important;
                width: 100% !important;
                .p-field.p-fluid {
                    margin-bottom: 2rem !important;
                    label {
                        display: block !important;
                        float: left;
                    }
                    small {
                        text-align: left !important;
                        float: left !important;
                        width: 100% !important;
                    }
                    div {
                        display: block;
                    }
                }
                .login-btn {
                    width: 100%;
                }
            }
        }
    }
}
</style>
