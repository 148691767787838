import {
    createRouter,
    createWebHistory
} from 'vue-router';
import {
    Token
} from './service/Token';

const token = new Token();
//console.log(token.hasToken())
function guest(to, from, next) {
    if (token.hasToken()) {
        next({
            name: 'dashboard'
        });
    } else next();
}

function guard(to, from, next) {
    if (token.hasToken()) {
        next();
    } else {
        next({
            name: 'login'
        });
    }
}

const routes = [{
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('./pages/Dashboard.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{
                parent: 'Dashboard',
                label: 'Dashboard'
            }],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
        beforeEnter: guest,
    },
    {
        path: '/change-password',
        name: 'changePassword',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/Users/ChangePassword.vue'),
    },
    {
        path: '/add-faq',
        name: 'addfaq',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/faq/addFaq.vue'),
    },
    {
        path: '/edit-faq/:id',
        name: 'editfaq',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/faq/editFaq.vue'),
    },
    {
        path: '/view-faq/:id',
        name: 'viewfaq',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/faq/viewFaq.vue'),
    },
    {
        path: '/faq',
        name: 'faq',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/faq/faqList.vue'),
    },
    //Inquiry Start
    {
        path: '/inquiry',
        name: 'inquiry',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/inquiry/InquiryList.vue'),
    },
  
    {
        path: '/edit-inquiry/:id',
        name: 'editinquiry',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/inquiry/editinquiry.vue'),
    },
    {
        path: '/view-inquiry/:id',
        name: 'viewinquiry',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/inquiry/viewinquiry.vue'),
    },
    {
        path: '/add-offline-inquiry',
        name: 'addofflineinquiry',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/inquiry/addofflineinquiry.vue'),
    },  
    //Notice Start
    {
        path: '/notice',
        name: 'notice',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/notice/NoticeList.vue'),
    },
    {
        path: '/add-notice',
        name: 'addnotice',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/notice/addNotice.vue'),
    },
    {
        path: '/edit-notice/:id',
        name: 'editnotice',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/notice/editNotice.vue'),
    },
    {
        path: '/view-notice/:id',
        name: 'viewinotice',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/customerManage/notice/viewNotice.vue'),
    },
     //Member Management Start
     {
        path: '/MemberManagement',
        name: 'MemberManagement',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/MemberManagement/MemberManageList.vue'),
    },
    {
        path: '/edit-member/:id',
        name: 'editmember',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/MemberManagement/EditMember.vue'),
    },
    {
        path: '/view-member/:id',
        name: 'viewmember',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/MemberManagement/viewMember.vue'),
    },
    //Dormant Member Management Start
    {
        path: '/DormantManagement',
        name: 'DormantMember',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/DormantManagement/DormantMemberList.vue'),
    },
    {
        path: '/DormantMemberView/:id',
        name: 'DormantMemberView',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/DormantManagement/DormantMemberView.vue'),
    },
    {
        path: '/DormantMemberEdit/:id',
        name: 'DormantMemberEdit',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/DormantManagement/DormantMemberEdit.vue'),
    },
    
    // Company Management
    {
        path: '/CompanyManagement',
        name: 'CompanyManagement',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/CompanyManagement/CompanyManagementList.vue'),
    },
    {
        path: '/add-company',
        name: 'addcompany',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/CompanyManagement/AddCompany.vue'),
    },
    {
        path: '/edit-company/:id',
        name: 'editcompany',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/CompanyManagement/EditCompany.vue'),
    },
    {
        path: '/view-company/:id',
        name: 'viewcompany',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/membershipManage/CompanyManagement/ViewCompany.vue'),
    },
    //View Profile
    {
        path: '/Users/view-profile',
        name: 'ViewProfile',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/Users/ViewProfile.vue'),
    },
    //Edit Profile
    {
        path: '/Users/edit-profile',
        name: 'EditProfile',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/Users/EditProfile.vue'),
    },
     //Admin Management
     {
        path: '/Admin-Management',
        name: 'AdminManagementList',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/AdminManagement/AdminManagementList.vue'),
    },
    //Add Admin Permission
    {
        path: '/Add-Admin-Permission',
        name: 'AddAdminPermission',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/AdminManagement/AddAdminPermission.vue'),
    },
    //Edit Admin  Permission
    {
        path: '/Edit-Admin-Permission',
        name: 'EditAdminPermission',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/AdminManagement/EditAdminPermission.vue'),
    },
    //Admin Management Add Admin
    {
        path: '/Add-Admin-Account',
        name: 'AddAdminAccount',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/AdminManagement/AddAdminAccount.vue'),
    },
    
    {
        path: '/View-Admin-Details/:id',
        name: 'ViewAdminDetails',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/AdminManagement/ViewAdminDetails.vue'),
    },
    {
        path: '/Edit-Admin-Details/:id',
        name: 'EditAdminDetails',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/AdminManagement/EditAdminDetails.vue'),
    },

    //License Management

    //1.Payment Management
    {
        path: '/PaymentManagement',
        name: 'PaymentManagementList',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/PaymentManagement/PaymentManagementList.vue'),
    },
    {
        path: '/ViewDepositConfirmation/:id',
        name: 'ViewDepositConfirmation',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/PaymentManagement/ViewDepositConfirmation.vue'),
    },
    {
        path: '/EditDepositConfirmation/:id',
        name: 'EditDepositConfirmation',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/PaymentManagement/EditDepositConfirmation.vue'),
    },
    //2.Package Management
    {
        path: '/PackageManagement',
        name: 'PackageManagementList',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/PackageManagement/PackageManagementList.vue'),
    },
    {
        path: '/Add-Package',
        name: 'Add-Package',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/PackageManagement/Add-Package.vue'),
    },
    {
        path: '/View-Package-Details/:id',
        name: 'View-Package-Details',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/PackageManagement/View-Package-Details.vue'),
    },
    {
        path: '/Delete-License-History',
        name: 'Delete-License-History',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/PackageManagement/Delete-License-History.vue'),
    },
    // Diagnosis Management Settings
    {
        path: '/diagnosis-management-settings',
        name: 'Diagnosis-Management-Settings',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/DiagnosisManagementSettings/DiagnosisManagementList.vue'),
    },
    {
        path: '/Upload-Diagnosis-Management-Settings',
        name: 'Upload-Diagnosis-Management-Settings',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/DiagnosisManagementSettings/Upload-Diagnosis-Management-Settings.vue'),
    },
    // Technical diagnosis settings
    {
        path: '/technical-diagnosis-settings',
        name: 'Technical-Diagnosis-Settings',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/TechnicalDiagnosisSetting/TechnicalDiagnosisSettings.vue'),
    },

    {
        path: '/Upload-Technical-Diagnosis-Management-Settings',
        name: 'Upload-Technical-Diagnosis-Management-Settings',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/TechnicalDiagnosisSetting/Upload-Technical-Diagnosis-Management-Settings.vue'),
    },
    // Technical diagnosis Detail
    {
        path: '/technical-diagnosis-Detail',
        name: 'Technical-Diagnosis-Detail',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/TechnicalDiagnosisDetails/TechnicalDiagnosisDetail.vue'),
    },
    // Evidence Settings
    {
        path: '/evidence-settings',
        name: 'Evidence-Settings',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/EvidenceSettings/EvidenceSetting.vue'),
    },
    // Evidence Edit Page
    {
        path: '/evidence-edit/:id',
        name: 'Evidence-Edit',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/EvidenceSettings/EditEvidence.vue'),
    },
    // Add Evidence page
    {
        path: '/evidence-add',
        name: 'Evidence-Add',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/EvidenceSettings/AddEvidence.vue'),
    },
    // Edit existing template from modal
    {
        path: '/edit-existing-template/:id',
        name: 'Edit-Existing-Template',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/EvidenceSettings/EditExistingTemplate.vue'),
    },
    // Add new template from modal
    {
        path: '/add-new-template',
        name: 'Add-New-Template',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/EvidenceSettings/AddNewTemplate.vue'),
    },
    // Information security authentication settings
    {
        path: '/information-security-authentication-settings',
        name: 'Information-Security-Authentication-Settings',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/InformationSecuritySettings/ListView.vue'),
    },
    {
        path: '/Risk-Evaluation-ManagementList',
        name: 'Risk-Evaluation-ManagementList',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/RiskEvaluationManagement/Risk-Evaluation-ManagementList.vue'),
    },
    {
        path: '/Upload-Risk-Evaluation-List',
        name: 'Upload-Risk-Evaluation-List',
        beforeEnter: guard,  // guard are required
        component: () => import('./pages/InformationSecurityCertificationManagement/RiskEvaluationManagement/Upload-Risk-Evaluation-List.vue'),
    },
    
    // There is no need to implement
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue'),
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {
            left: 0,
            top: 0
        };
    },
});

export default router;